import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { YearObject } from "../../data/YearObject";

// Components
import ContentHeader from "../../components/ContentHeader";
import PersonCard from "../../components/PersonCard";
import YearSelector from "../../components/YearSelector";

// Current year query
// Get the year from the page context
export const query = graphql`
  query ManagementQuery($year: Int!) {
    currentYear {
      year
    }
    years(year: { eq: $year }) {
      year
      color
      group_picture {
        childImageSharp {
          gatsbyImageData(
            width: 1000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER, cropFocus: ATTENTION }
          )
        }
      }
      people {
        id
        name {
          full
        }
        company {
          name
          website
          logo {
            childImageSharp {
              gatsbyImageData(
                height: 40
                width: 200
                placeholder: TRACED_SVG
                transformOptions: { fit: INSIDE }
              )
            }
          }
        }
        linkedin
        year(year: $year) {
          role {
            importance
            title
            emails
          }
        }
        picture {
          childImageSharp {
            gatsbyImageData(
              width: 256
              placeholder: TRACED_SVG
              transformOptions: { fit: COVER, cropFocus: ATTENTION }
            )
          }
        }
      }
    }
  }
`;

export type ManagementPageProps = {
  data: {
    currentYear: {
      year: number;
    };
    years: YearObject;
  };
};

export default class ManagementPage extends React.Component<ManagementPageProps> {
  render() {
    const year = this.props.data.years;
    const groupPicture = getImage(year.group_picture);
    return (
      <div className="flex flex-col items-center">
        <ContentHeader
          title="Bestuur"
          background={groupPicture}
          backLink={`/${year.year}`}
          colored_banner={true}
          backLabel={`Justitia ${year.year}`}
        />
        <div className="flex flex-col items-center gap-20 lg:px-12 px-6 pt-24 pb-12 w-full">
          {year.people?.length === 0 ? (
            <p
              className="text-2xl font-bold text-center text-opacity-80 lg:px-12 px-6 pt-12"
              style={{ color: year.color }}
            >
              Er zijn nog geen bestuursleden bekend voor Justitia {year.year}.
            </p>
          ) : null}
          <div className="grid lg:grid-cols-2 gap-x-10 gap-y-16 lg:px-12 px-6">
            {year.people
              ?.sort((a, b) =>
                a.year.role.importance > b.year.role.importance ? -1 : 1
              )
              .map((person) => {
                const picture = getImage(person.picture);
                const logo = getImage(person.company.logo);
                return (
                  <PersonCard
                    key={person.id}
                    name={person.name.full}
                    role={person.year?.role?.title}
                    emails={
                      year.year === this.props.data.currentYear.year
                        ? person.year?.role?.emails
                        : []
                    }
                    linkedin={person.linkedin}
                    picture={picture}
                    company={{
                      name: person.company.name,
                      website: person.company.website,
                    }}
                  />
                );
              })}
          </div>

          <YearSelector url="bestuur" year={year.year} />
        </div>
      </div>
    );
  }
}

export function Head({ data }: { data: any }) {
  const year = data.years;
  return <title>Bestuur - Justitia {year.year}</title>;
}
