import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import BalancingCard from "./BalancingCard";
import LinkedInLogo from "../images/social-icons/linkedin.svg";

export interface PersonCardProps {
  key: string;
  name: string;
  role: string;
  color?: string;
  company: {
    name: string;
    website: string;
  };
  emails: string[];
  picture: IGatsbyImageData | undefined;
  linkedin: string | undefined;
}

export default class PersonCard extends React.Component<PersonCardProps> {
  render() {
    return (
      <div
        className="bg-slate-50 p-4 rounded-2xl flex flex-col gap-4 border border-slate-100 shadow-sm"
        key={this.props.key}
      >
        <div className="flex flex-col gap-6">
          <div className="flex gap-4 items-center">
            <div className="relative flex-none h-32 w-32">
              <div className="absolute top-0 left-0 h-32 w-32 bg-opacity-20 rounded-2xl rotate-6 bg-slate-300" />
              {this.props.picture ? (
                <GatsbyImage
                  image={this.props.picture}
                  alt={`Picture of ${this.props.name}`}
                  className="aspect-square"
                  imgClassName="rounded-2xl z-10 h-32 w-32 border-4 border-black aspect-square"
                />
              ) : null}
            </div>
            <div className="flex flex-col gap-3 w-full">
              <div>
                <p
                  className="text-xl font-bold font-serif text-opacity-60"
                  style={{ color: this.props.color ?? "#1f0c35" }}
                >
                  {this.props.role}
                </p>

                <p className="text-3xl font-black text-slate-900 text-opacity-80">
                  {this.props.name}
                </p>
              </div>
              <div>
                <a
                  href={this.props.company.website}
                  className="hover:opacity-50 active:opacity-20 transition-opacity cursor-pointer"
                >
                  {this.props.company.name ? (
                    <p className="font-bold">{this.props.company.name}</p>
                  ) : null}
                </a>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-row justify-between gap-2 ${
              this.props.emails.length > 0 ? "-mb-10" : ""
            }`}
          >
            {this.props.linkedin ? (
              <a
                href={this.props.linkedin}
                target="_blank"
                rel="noreferrer"
                className="-mt-4"
              >
                <LinkedInLogo
                  className="h-6 w-6 fill-current  hover:opacity-70 active:opacity-40 transition-opacity"
                  style={{ color: this.props.color ?? "#1f0c35" }}
                />
              </a>
            ) : null}
            <div className="flex flex-row justify-end gap-2 -mt-2 w-full">
              {this.props.emails.map((email) => {
                return (
                  <a href={`mailto:${email}`} key={email}>
                    <div
                      className="px-3 py-2 text-white rounded-lg flex gap-2 items-center shadow-lg hover:scale-105 active:scale-95 hover:shadow-xl active:shadow-md transition-all"
                      style={{ background: this.props.color ?? "#1f0c35" }}
                    >
                      <div>
                        <p className="text-sm font-medium tracking-wider">
                          {email.replace("@stichtingjustitia.nl", "")}
                        </p>
                        <p className="text-xs font-normal text-white text-opacity-50">
                          @stichtingjustitia.nl
                        </p>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
