import React from "react";
import { graphql, StaticQuery } from "gatsby";

// Components
import Link from "./Link";

type YearButtonProps = {
  year: number;
  url: string;
  index: number;
  exists: boolean;
  color?: string;
  selectorFocus: boolean;
  focusUpdate: (focused: boolean) => void;
};

class YearButton extends React.Component<YearButtonProps> {
  state = {
    focused: false,
  };
  render() {
    const { year, url, index, selectorFocus, exists, focusUpdate } = this.props;
    const size = 1.5 - 0.2 * index;
    const margin = index < 0 ? 1.5 : 1 - 0.1 * index;
    const opacity = (index < 0 ? 1 : 0.7) - 0.15 * index;
    return (
      <div className={`${!exists ? "pointer-events-none" : ""} select-none`}>
        <Link
          to={url.replace(/\/$/, "")}
          className={`btn btn-outline-primary`}
          style={{
            borderColor: "#1f0c35",
            color: exists ? "#1f0c35" : "gray",
          }}
        >
          <div
            onMouseEnter={() => {
              this.setState({ focused: true });
              focusUpdate(true);
            }}
            onMouseLeave={() => {
              this.setState({ focused: false });
              focusUpdate(false);
            }}
          >
            <p
              style={{
                fontSize: `${
                  this.state.focused ? 1.8 : selectorFocus ? 1.2 : size
                }rem`,
                margin: `0 ${selectorFocus ? 1 : margin}rem`,
                opacity: this.state.focused ? 1 : selectorFocus ? 0.6 : opacity,
              }}
              className="font-black transition-all"
            >
              {year}
            </p>
          </div>
        </Link>
      </div>
    );
  }
}

type YearSelectorProps = {
  year: number;
  url?: string;
  color?: string;
};

export class YearSelector extends React.Component<YearSelectorProps> {
  state = {
    focused: false,
  };

  render() {
    const selectedYear = this.props.year;
    return (
      <StaticQuery
        query={graphql`
          query YearSelectorQuery {
            currentYear {
              year
            }
            allYears(filter: { published: { eq: true } }) {
              nodes {
                year
              }
            }
          }
        `}
        render={(data) => {
          // Get list of all years sorted ascending by year
          const years = data.allYears.nodes
            .map((node: { year: number }) => node.year)
            .sort((a: number, b: number) => a - b);

          // Create an array of three years before the selected year, but do not use the year array
          const yearsBefore = Array.from(Array(3).keys())
            .map((i) => selectedYear - i - 1)
            .sort((a: number, b: number) => a - b);

          // Create an array of three years after the selected year, but do not use the year array
          const yearsAfter = Array.from(Array(3).keys())
            .map((i) => selectedYear + i + 1)
            .sort((a: number, b: number) => a - b);

          return (
            <div className="flex flex-col items-center w-full">
              <p className="sm:text-lg text-sm font-bold text-opacity-80">
                Wil je de pagina van een ander jaar bekijken?
              </p>
              <p className="sm:text-base text-xs text-opacity-60">
                Klik dan op een van de onderstaande jaren
              </p>
              <div className="w-full overflow-hidden">
                <div className="flex flex-row justify-evenly w-full h-20">
                  <div className="w-full flex flex-row items-center justify-end">
                    {yearsBefore.map((year: number, index: number) => {
                      const invertedIndex = yearsBefore.length - index - 1;
                      return (
                        <YearButton
                          color={this.props.color ?? "#1f0c35"}
                          key={year}
                          year={year}
                          url={`/${year}/${this.props.url ?? ""}`}
                          index={invertedIndex}
                          exists={years.includes(year)}
                          selectorFocus={this.state.focused}
                          focusUpdate={(focused: boolean) => {
                            this.setState({ focused });
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="flex items-center">
                    {/* The selected year */}
                    <YearButton
                      color={this.props.color ?? "#1f0c35"}
                      year={selectedYear}
                      url={`/${selectedYear}/${this.props.url}`}
                      index={-5}
                      exists={true}
                      selectorFocus={this.state.focused}
                      focusUpdate={(focused: boolean) => {
                        this.setState({ focused });
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-row items-center justify-start">
                    {yearsAfter.map((year: number, index: number) => {
                      return (
                        <YearButton
                          color={this.props.color ?? "#1f0c35"}
                          key={year}
                          year={year}
                          url={`/${year}/${this.props.url ?? ""}`}
                          index={index}
                          exists={years.includes(year)}
                          selectorFocus={this.state.focused}
                          focusUpdate={(focused: boolean) => {
                            this.setState({ focused });
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              <Link to="/archief">
                <p
                  className="px-6 py-2 rounded-lg text-white text-base font-semibold tracking-wide hover:opacity-80 active:opacity-50 transition-opacity"
                  style={{ background: "#1f0c35" }}
                >
                  Bekijk het volledige archief
                </p>
              </Link>
            </div>
          );
        }}
      />
    );
  }
}

export default YearSelector;
